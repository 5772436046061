





















export default {
  props: {
    emptyEstimate: {
      type: Number,
      default: 0,
    },
    halfEstimate: {
      type: Boolean,
      default: false,
    },
    fullEstimate: {
      type: Number,
      default: 0,
    },
  },
}
