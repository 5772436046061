var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section-basic',{attrs:{"section-data":_vm.sectionData},scopedSlots:_vm._u([{key:"sectionContent",fn:function(){return [_c('f-container',{class:{ 'reviews-list': true, 'reviews-list-alternate': _vm.alternate }},[_vm._l((_vm.countDisplayedReviewsAtMobile),function(num,i){return _c('f-col',{key:i,attrs:{"sm":2}},[(_vm.reviewsList[num - 1])?_c('card-review',{attrs:{"review":_vm.reviewsList[num - 1],"alternate":_vm.alternate}}):_vm._e()],1)}),_vm._v(" "),(_vm.showMoreVisible)?_c('button',{staticClass:"btn--show-more",on:{"click":_vm.addMoreBlocks}},[_c('span',[_vm._v("Показать еще")]),_vm._v(" "),_c('svg-icon',{staticClass:"icon",attrs:{"name":"icon/arrow_up_big"}})],1):_vm._e()],2),_vm._v(" "),_c('agile',_vm._b({ref:"agile",class:{
        'reviews-container': true,
        'reviews-container-alternate': _vm.alternate,
      }},'agile',_vm.agileOptions,false),_vm._l((_vm.reviewsList),function(review,i){return _c('div',{key:i,class:{
          'slide-container': true,
          alternate: _vm.alternate,
        }},[_c('card-review',{attrs:{"review":review,"alternate":_vm.alternate}})],1)}),0),_vm._v(" "),(!_vm.isMobile || _vm.alternate)?_c('div',{class:{
        buttons: true,
        'alternate-buttons': _vm.alternate,
      }},[(_vm.alternate)?_c('img',{staticClass:"review-source-logo",attrs:{"src":require("@/assets/images/sravni.png"),"loading":"lazy","alt":"Сравни ру лого"}}):_vm._e(),_vm._v(" "),(!_vm.isMobile)?_c('div',[_c('button',{staticClass:"button prev",on:{"click":_vm.prevSlide}},[_c('svg-icon',{attrs:{"name":"icon/arrow_short"}})],1),_vm._v(" "),_c('button',{staticClass:"button next",on:{"click":_vm.nextSlide}},[_c('svg-icon',{attrs:{"name":"icon/arrow_short"}})],1)]):_vm._e()]):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }