








































































import {
  computed,
  onMounted,
  PropType,
  ref,
  useStore,
} from '@nuxtjs/composition-api'
import SectionBasic from '~/components/SectionBasic.vue'
import { CardReview as TypeReview } from '~/contracts/Cards'
import CardReview from '~/components/Cards/CardReview.vue'
import { sectionCoreProps } from '@/mixins/sectionCore'

export default {
  name: 'SectionReviews',
  components: { CardReview, SectionBasic },
  mixins: [sectionCoreProps],
  props: {
    reviewsList: {
      type: Array as PropType<TypeReview[]>,
      default: () => [],
    },
    countReviewsAtSlide: {
      type: Number,
      required: true,
    },
    alternate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore()
    const agile = ref(null)
    const countDisplayedReviewsAtMobile = ref(3)

    const isMobile = computed(() => store.getters['mediaQuery/isMobileMq'])
    const showMoreVisible = computed(
      () => countDisplayedReviewsAtMobile.value < props.reviewsList.length
    )

    const agileOptions = computed(() => ({
      navButtons: false,
      infinite: false,
      dots: props.alternate,
      slidesToShow: 1,
      swipeDistance: isMobile.value ? 50 : 10000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: false,
            navButtons: false,
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 1023,
          settings: {
            dots: false,
            navButtons: false,
            slidesToShow: 3,
          },
        },
      ],
    }))

    const prevSlide = () => {
      agile.value.goToPrev()
    }
    const nextSlide = () => {
      agile.value.goToNext()
    }
    const addMoreBlocks = () => {
      countDisplayedReviewsAtMobile.value += 3
    }

    onMounted(() => {
      store.commit('popupReviewDetail/SET_SHOW_POPUP_DETAIL', true)
    })

    return {
      agileOptions,
      showMoreVisible,
      countDisplayedReviewsAtMobile,
      isMobile,
      agile,
      prevSlide,
      nextSlide,
      addMoreBlocks,
    }
  },
}
