var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    card: true,
    alternate: _vm.alternate,
  },style:(_vm.styles)},[_c('div',{staticClass:"card-content"},[_c('div',[_c('client-only',[_c('div',{staticClass:"card__top"},[(_vm.review.reviewDate && !_vm.alternate)?_c('p',{staticClass:"card__date text--second"},[_vm._v("\n            "+_vm._s(_vm.adaptedDate())+"\n          ")]):_vm._e(),_vm._v(" "),_c('block-review-estimate',{attrs:{"empty-estimate":_vm.emptyEstimate,"full-estimate":_vm.fullEstimate,"half-estimate":!!(_vm.review.estimate % 1)}})],1)]),_vm._v(" "),(_vm.review.title)?_c('div',{staticClass:"card__title text--basic",domProps:{"innerHTML":_vm._s(_vm.review.title)}}):_vm._e(),_vm._v(" "),_c('p',{staticClass:"card__description",domProps:{"innerHTML":_vm._s(_vm.review.description)}}),_vm._v(" "),(!_vm.alternate)?_c('button',{staticClass:"card__more-btn link--basic",on:{"click":_vm.openDetailHandle}},[_vm._v("\n        Читать далее\n      ")]):_vm._e()],1),_vm._v(" "),(
        (_vm.review.linkReviewAuthor ||
          _vm.review.imageReviewAuthor ||
          _vm.review.nameReviewAuthor) &&
        !_vm.alternate
      )?_c('div',[(_vm.review.linkReviewAuthor)?_c('Link',{staticClass:"card__link",attrs:{"url":_vm.review.linkReviewAuthor,"target":"_blank"}},[(_vm.review.imageReviewAuthor)?_c('f-picture',{staticClass:"card__author-img",attrs:{"sizes":[50, 50],"is-cover":false,"quality":100,"source-desk":_vm.review.imageReviewAuthor,"source-mob":_vm.review.imageReviewAuthor}}):_vm._e(),_vm._v(" "),(_vm.review.nameReviewAuthor)?_c('span',{staticClass:"card__author-name",domProps:{"innerHTML":_vm._s(_vm.review.nameReviewAuthor)}}):_vm._e()],1):_c('div',{staticClass:"card__link"},[(_vm.review.imageReviewAuthor)?_c('f-picture',{staticClass:"card__author-img",attrs:{"sizes":[50, 50],"quality":100,"is-cover":false,"source-desk":_vm.review.imageReviewAuthor,"source-mob":_vm.review.imageReviewAuthor}}):_vm._e(),_vm._v(" "),(_vm.review.nameReviewAuthor)?_c('span',{staticClass:"card__author-name",domProps:{"innerHTML":_vm._s(_vm.review.nameReviewAuthor)}}):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(_vm.alternate)?_c('div',{staticClass:"card__bottom-info"},[_c('client-only',[(_vm.review.reviewDate)?_c('p',{staticClass:"card__date text--second"},[_vm._v("\n          "+_vm._s(_vm.adaptedDate())+"\n        ")]):_vm._e()]),_vm._v(" "),_c('block-buttons',{attrs:{"alignment":'right',"button-actions":_vm.detailButtonAlternate},on:{"button-click":_vm.openDetailHandle}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }