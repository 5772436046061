





































































































import { computed, PropType, useStore } from '@nuxtjs/composition-api'
import Link from '@/components/Link.vue'
import { dateAdapter } from '@/apollo/adapters/modules'
import { CardReview as TypeReview } from '@/contracts/Cards'
import BlockReviewEstimate from '@/components/BlockReviewEstimate.vue'
import BlockButtons from '@/components/BlockButtons.vue'

export default {
  name: 'CardReview',
  components: { BlockButtons, BlockReviewEstimate, Link },
  props: {
    review: {
      type: Object as PropType<TypeReview>,
      default: () => ({}),
    },
    alternate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { commit } = useStore()
    const adaptedDate = () =>
      dateAdapter(props.review.reviewDate, true).replace(' г.', '')

    const maxEstimate = 5
    const emptyEstimate = computed(
      () => maxEstimate - Math.ceil(+props.review.estimate)
    )
    const fullEstimate = computed(() => Math.floor(+props.review.estimate))

    const styles = computed(() => ({
      '--card-review-background': props.review.backgroundColor,
      '--card-review-view-lines': props.review.countLines
        ? props.review.countLines
        : props.alternate
        ? 9
        : 4,
    }))

    const openDetailHandle = () => {
      commit('popupReviewDetail/SET_IS_ALTERNATE_VIEW', props.alternate)
      commit('popupReviewDetail/SET_REVIEW', props.review)
      commit('popupReviewDetail/SET_OPEN_POPUP_DETAIL', true)
    }

    const detailButtonAlternate = [
      {
        id: '1',
        padding: false,
        label: 'Читать дальше',
        labelColor: '#D70E4B',
        labelHoverColor: '#990032',
        name: 'button',
        arrow: true,
        type: 'button',
        color: '',
      },
    ]

    return {
      styles,
      detailButtonAlternate,
      maxEstimate,
      emptyEstimate,
      fullEstimate,
      adaptedDate,
      openDetailHandle,
    }
  },
}
